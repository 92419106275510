define(['$console', 'app', 'productImageCarousel', 'productImageZoom', '$window', 'accessibleModalHelper'], ($console, app, productImageCarousel, productImageZoom, $window, accessibleModalHelper) => {

  const freeProductModal = () => {
    const component = {};

    const _config = {
      attrib: {
        popUpSelect: 'data-popUp-select-id'
      },
      selectors: {
        freeGiftInfoIcon: '[data-js-element=freeProductSelection_freeGiftInfoIcon]',
        freeGiftInfoModal: '[data-open-freeproductinfo-modal]',
        closeModalButton: '[data-close]',
        popUpGiftError: '[data-js-element=responsiveBasket_popUpError]',
        accordionContainer: '[data-accordion-container]',
        accordionHeader: '[data-accordion-header]',
        accordionBody: '[data-accordion-body]',
        modalTitle: '#freeProductModal_modalTitle',
      },
      classNames: {
        freeProductItemSelected: 'freeProductSelection_itemProductBlock_selected',
        showFreeGiftInfoModal: 'freeProductModal_productInfo-show',
        showErrorPopup: 'responsiveBasket_error-show',
        accordionShowClass: 'freeProductModal_accordion-show',
        accordionHideClass: 'freeProductModal_accordion-hide',
        popUpLock: 'js-popup-lock',
        closeModalClass: 'freeProductModal_close'
      }
    };

    const _init = element => {
      component.element = element;
      if (!component.element || component.element instanceof HTMLElement === false) {
        $console.error('freeProductModal.init was called without the expected HTMLElement');
        return;
      }
      component.bindIcons();

      const alreadySelected = component.element.querySelector(`.${component.config.classNames.freeProductItemSelected}`);

      if(!alreadySelected) return;

      component.getElements();
      return component;
    };

    const _getElements = () => {
      component.freeGiftInfoModal = component.element.querySelector(component.config.selectors.freeGiftInfoModal);
      component.closeModalButton = component.element.querySelector(component.config.selectors.closeModalButton);
      component.attachListeners();
    };

    const _bindIcons = () => {
      const infoIcon = document.querySelectorAll(component.config.selectors.freeGiftInfoIcon);
      if (!infoIcon.length) {
        return;
      }
      Array.from(infoIcon).map(el => el.addEventListener('click', component.showModal, false));
    };

    const _attachListeners = () => {
      component.freeGiftInfoModal && component.freeGiftInfoModal.addEventListener('click', component.modalClose, false);
      component.closeModalButton && component.closeModalButton.addEventListener('click', component.hideModal, false);
    };

    const _showModal = (e) => {
      e.preventDefault();
      e.stopPropagation();

      component.popUpSelectId = e.currentTarget.parentElement.getAttribute(component.config.attrib.popUpSelect);
      app.publish('tracking/record', 'freeProductModal', 'Clicked on Product Info', 'product Id', component.popUpSelectId);
      component.ajaxInfoPopUp(component.popUpSelectId);
    };

    const _modalClose = (e) => {
      if (e.target.classList.contains(component.config.classNames.showFreeGiftInfoModal)) {
        component.hideModal();
      }
    };

    const _hideModal = () => {
      component.freeGiftInfoModal.classList.remove(component.config.classNames.showFreeGiftInfoModal);
      document.documentElement.classList.remove(component.config.classNames.popUpLock);
      document.body.style.top = '';
      app.element.scrollTo(component.prevScrollTop);
      component.accessibleModalHelper && component.accessibleModalHelper.close();
    };

    const _accordionClickHandler = (e) => {
      e.stopPropagation();
      const accordion = e.target;
      const accordionParent = accordion.parentElement;
      const accordionShow = component.element.querySelector(`.${component.config.classNames.accordionShowClass}`);
      if (accordion.getAttribute('aria-expanded') === 'true') {
        accordionParent.classList.remove(component.config.classNames.accordionShowClass);
        accordionParent.classList.add(component.config.classNames.accordionHideClass);
        accordion.setAttribute('aria-expanded', 'false');
      } else {
        accordionParent.classList.remove(component.config.classNames.accordionHideClass);
        accordionParent.classList.add(component.config.classNames.accordionShowClass);
        accordion.setAttribute('aria-expanded', 'true');
      }

      if (accordionShow && accordionParent !== accordionShow) {
        accordionShow.classList.remove(component.config.classNames.accordionShowClass);
        accordionShow.classList.add(component.config.classNames.accordionHideClass);
        accordionShow.querySelector(component.config.selectors.accordionHeader).setAttribute('aria-expanded', 'false');
      }
    };

    const _ajaxInfoPopUp = (productId) => {
      app.ajax.get({
        url: `${productId}.productPopUp`,
        dataType: 'JSON',
        success: component.infoPopUpSuccess,
        error: component.infoPopUpError
      });
    };

    const _infoPopUpSuccess = (response) => {
      component.prevScrollTop = $window.pageYOffset;

      component.element.innerHTML = response;
      component.getElements();
      document.documentElement.classList.add(component.config.classNames.popUpLock);
      document.body.style.top = `${-component.prevScrollTop}px`;
      component.freeGiftInfoModal.classList.add(component.config.classNames.showFreeGiftInfoModal);
      app.publish('tracking/record', 'freeProductModal', 'Viewed Product Info', 'viewed product', component.popUpSelectId);
      component.accessibleModalHelper = new accessibleModalHelper(component.freeGiftInfoModal, component.hideModal, component.element.querySelector(component.config.selectors.modalTitle));
      component.initialisePopUpComponents();
    };

    const _infoPopUpError = () => {
      const popUpGiftError = document.querySelector(component.config.selectors.popUpGiftError);
      component.fadeOutError(popUpGiftError);
    };

    const _initialisePopUpComponents = () => {
      Array.from(component.element.querySelectorAll(component.config.selectors.accordionHeader))
        .forEach(accordionHeader => accordionHeader.addEventListener('click', component.accordionClickHandler, false));

      const productImageCarouselEl = document.querySelector('[data-component=productImageCarousel]');
      productImageCarouselEl && new productImageCarousel().init(productImageCarouselEl);

      const productImageZoomEl = document.querySelector('[data-component=productImageZoom]');
      productImageZoomEl && new productImageZoom().init(productImageZoomEl);
    };

    const _fadeOutError = (el) => {
      el.classList.remove(component.config.classNames.showErrorPopup);
      void el.offsetWidth;
      el.classList.add(component.config.classNames.showErrorPopup);
    };

    component.config = _config;
    component.init = _init;
    component.getElements = _getElements;
    component.bindIcons = _bindIcons;
    component.attachListeners = _attachListeners;
    component.showModal = _showModal;
    component.hideModal = _hideModal;
    component.modalClose = _modalClose;
    component.accordionClickHandler = _accordionClickHandler;
    component.ajaxInfoPopUp = _ajaxInfoPopUp;
    component.infoPopUpSuccess = _infoPopUpSuccess;
    component.infoPopUpError = _infoPopUpError;
    component.initialisePopUpComponents = _initialisePopUpComponents;
    component.fadeOutError = _fadeOutError;

    return component;
  };

  return freeProductModal;

});

